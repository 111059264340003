import React, { Component } from 'react';

import { getId } from '../../utils/tools';
import LOGO from '../../assets/images/logo.png'

export default class SearchParams extends Component {

  render() {
    let { categories, onChange, onSubmit } = this.props;
    return (
      <form className="search-form-params" onSubmit={onSubmit}>
        <div className="search-form-params__categories">
          {
              categories.edges.map(
                ({node: category }) => (
                  <label htmlFor={category.id} className="category-item" key={category.id}>
                    <input
                      value={getId(category._links)}
                      onChange={onChange}
                      className="category-item__radio category-item_hidden"
                      name="category"
                      type="radio"
                      id={category.id}
                    />
                    <div
                      className="category-item__image js-lazy capitalize"
                      style={{backgroundImage: `url(${category.image ? category.image.src : LOGO})`}}
                      data-background={category.image ? category.image.src : LOGO}
                     />
                    <strong className="category-item__name capitalize">{category.name}</strong>
                  </label>
                )
              )
            }
        </div>
        <div className="search-form-params__price-edges ">
          <div className="search-form-params__field-container search-form-params_inline">
            <label htmlFor="min">Prix min: </label>
            <input type="number" id="min" name="min_price" min='100' defaultValue={100} onChange={onChange} placeholder='1000' className="search-form-params__input capitalize"/>
          </div>
          <div className="search-form-params__field-container search-form-params_inline">
            <label htmlFor="max">Prix max: </label>
            <input type="number" id="max"name="max_price" min='100' defaultValue={100000} onChange={onChange} placeholder='1000' className="search-form-params__input capitalize"/>
          </div>
        </div>
        <div>
          <button className="search-form-params__button" type='submit'>Chercher</button>
        </div>
      </form>
    );
  }

}
