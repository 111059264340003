import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import SearchParamsForm from "../components/search/SearchParams";
import Card from "../components/Card";

import { searchProducts } from "../utils/actions/product";
import { formatData } from "../utils/tools";

import "../assets/css/search.css";

//const API_URL = process.env.WC_API_DEPLOY_URL || '127.0.0.1/gatsby-woo';
const API_URL = process.env.WC_API_DEPLOY_URL || 'store.aalladine.com/';

class SearchPage extends React.Component {

  state = {
    loading: false,
    querystring: {
      search: '',
      category: '',
    },
    products: [],
  }

  /**
   * search for products request then update state
   */
   searchForProducts = data => {
     let params='';
     for (let key in data) {
       if (data.hasOwnProperty(key)) {
         params += `${key}=${data[key]}&`;
       }
     }
     this.setState({ loading : true });
     searchProducts(
       `https://${API_URL}/wp-json/wc/v3/products?${params}`
     )
       .then(products => {
         this.setState({ products: formatData(products, 4), loading: false })
       })
       .catch( err => {
         alert('Ressayer plus tard ou verifyez votre connexion internet (Wifi/données) :(');
         this.setState({ loading: false })
       })
   }

  /**
   * @name changeSearchState
   * change the state when user type another searchkey
   * in search page and this function called in searchbar
   * @see {SearchForm} component
   * @param {String} search new search key
   */
   changeSearchState = (search, category) => {
     this.setState(prevState => ({
       querystring: {
         ...prevState.querystring,
         search,
         category
       },
     }));
     this.searchForProducts(this.state.querystring);
   }

   /**
    * @name onChange
    * change a search params
    * @param {HTMLElement}  current html element dom
    */
   onChange = ({ target }) => this.setState(prevState => ({
     querystring: {
       ...prevState.querystring,
       [target.name]: target.value
     }
   }))

   /**
    * on submit event of
    */
    onSubmit = e => {
      e.preventDefault();
      this.searchForProducts(this.state.querystring)
    }

  /**
   * @name parseQueryString
   * parse a querystring to an structure object
   * contain a different key and value of string
   * @param {String} str the querystring to parse
   * @return {Object} contain a list of key/value
   * of each param in querystring
   */
   parseQueryString = str => {
     str = str.replace('?', '');
     let newObject = {};
     str.split('&').forEach( item => {
       let res = item.split('=');
       newObject[ res[0] ] = res[1];
     });
     return newObject;
   }

  /**
   * @name renderResuts
   * render a resutls ot loading indicator
   */
   renderResuts = () => {
     return (
       this.state.loading ?
         (<div className="loading-indicator loading-background center">Chargement ..</div>) :
         (
             this.state.products.map( (rows, i) => (
               <div className="row" key={i}>
                 {
                   rows.length ?
                     rows.map(product => {
                       return (
                         <Card key={product.id} data={product} />
                       )
                     }) :
                     <h3 className='search-products-result__title'>Oop's! il n'y a pas des resultats pour votre rechereche</h3>
                 }
               </div>
             ))
         )
     )
   }

  componentDidMount() {
    if( typeof window !== `undefined` ) {
      let { search } = window.location;
      let querystring = this.parseQueryString(search);
      this.setState({ querystring, loading: search.trim().length!== 0 ? true : false });
      this.searchForProducts(querystring);
    }
  }

  render() {
    let { search } = this.state.querystring;
    let { allWcProductsCategories: categories } = this.props.data;
    let categoriesKeys = categories.edges.map(({node: category}) => category.name);

    return (
      <Layout
        navigate={this.props.navigate}
        categories={categories}
        searchKey={search}
        onChangeSearchKey={this.changeSearchState}
        onChange={this.onChange}
      >
        <SEO 
          title={`Rechercher et trouvez ${search}`}
          keywords={[`aalladine`, `ecommerce boutique`, ...categoriesKeys]} />
        <div className="page-search">

          <div className="search-page-header-content">

            <h2 className='search-page-header-content__title capitalize'>
              Rechereche de :
              &nbsp;<Link to={`/search?search=${search}`} className="capitalize">{search}</Link>
            </h2>

            <SearchParamsForm
              categories={categories}
              onChange={this.onChange}
              onSubmit={this.onSubmit}
             />

          </div>

          <div className="products-list row">
            { this.renderResuts() }
          </div>

        </div>
      </Layout>
    );
  }

}

export default SearchPage;

export const pageQuery = graphql`
  query {
    allWcProductsCategories (
      filter : { slug : {nin: ["uncategorized", "non-classe"]} },
      sort: { fields: [count], order: DESC }
     ) {
      edges {
       node {
         id
         name
         display
         slug
         image {
           src
           name
         }
         _links{
           self{
             href
           }
         }
         count
       }
      }
    }
  }
`;
